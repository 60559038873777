import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const Footer = ({categories}) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('v-pills-payment');


    return (
        <footer className="py-5 footer mt-auto">
            <div className={"container"}>
                <div className="d-flex justify-content-around footer_container">
                    <div className="px-3 footer_item">
                        <h5>Каталог</h5>
                        <ul className="nav flex-column">
                            {
                                categories?.map((category, index) => (
                                    <div key={index}>
                                        <li className="nav-item mb-2" onClick={() => navigate('/category/' + category.alias)}><a className="nav-link p-0 text-body-secondary cursor-pointer">{category.category_name}</a></li>
                                    </div>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="px-3 footer_item">
                        <h5>Информация</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a onClick={() => navigate('/help#v-pills-payment')} className="nav-link p-0 text-body-secondary cursor-pointer">Условия оплаты</a></li>
                            <li className="nav-item mb-2"><a onClick={() => navigate('/help#v-pills-delivery')} className="nav-link p-0 text-body-secondary cursor-pointer">Условия доставки</a></li>
                            <li className="nav-item mb-2"><a onClick={() => navigate('/help#v-pills-guarantee')} className="nav-link p-0 text-body-secondary cursor-pointer">Гарантия на товар</a></li>
                            <li className="nav-item mb-2"><a onClick={() => navigate('/help#v-pills-privacy-policy')} className="nav-link p-0 text-body-secondary cursor-pointer">Политика конфиденциальности</a></li>
                        </ul>
                    </div>

                    <div className="px-3 footer_item">
                        <h5>Компания</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a onClick={() => navigate('/about#v-pills-about')} href="#" className="nav-link p-0 text-body-secondary cursor-pointer">О компании</a></li>
                            <li className="nav-item mb-2"><a onClick={() => navigate('/about#v-pills-oferta')} className="nav-link p-0 text-body-secondary cursor-pointer">Оферта</a></li>
                        </ul>
                    </div>
                </div>
                <div className={"text-center m-3"}>
                    Все вопросы вы можете согласовать с нашим менеджером в Whatsapp по телефону: +7 996 221‑79‑03
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between pt-4 mt-4 border-top">
                    <p>© 2024 Интернет-магазин ВоенторгЗвезда. Все права защищены</p>
                </div>
            </div>

        </footer>
    )};
export default Footer;