import React from 'react';
import {Carousel} from "react-bootstrap";
import dron from '../assets/img/dron.jpg'
import kaska from '../assets/img/kaska.jpg'
import night from '../assets/img/night.jpg'
const Slider = () => {
    return (
        <div>
        <Carousel>
            <Carousel.Item interval={3000}>
                <img width={1300} height={900} src={dron} alt=""/>
                <Carousel.Caption>
                    <h3>DJI Mavic 2 Pro</h3>
                    <p>Дальность полета не менее 30 километров</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
                <img width={1300} height={900} src={night} alt=""/>
                <Carousel.Caption>
                    <h3>AGM NVG-40 NL1</h3>
                    <p>
                        Многофункциональные и полноценные очки, используются для наблюдения за целью и территорией в ночное время, при плохих погодных условиях
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
                <img width={1300} height={900} src={kaska} alt=""/>
                <Carousel.Caption>
                    <h3>Exfil Ballistic Team Wendy</h3>
                    <p>Гибридная модель повышенной прочности с уникальной геометрией для оптимальной посадки</p>
                </Carousel.Caption>
            </Carousel.Item>

        </Carousel>
        </div>
    );
};

export default Slider;
