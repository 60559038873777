import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Card from "../components/Card";

const Category = () => {
    const { alias } = useParams();
    const [products, setProducts] = useState([])
    const [categoryTitle, setCategoryTitle] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getProducts()
    }, [alias]);

    const getProducts = async () => {
        const url = process.env.REACT_APP_API_URL + 'products-by-category/' + alias
        let response = await fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            let json = await response.json()

            if (json) {
                setProducts(json.products)
                setCategoryTitle(json.category_name)
                setIsLoading(false);
            }
        } else {
            const errorText = await response.text();
            const errorObject = JSON.parse(errorText);
            setIsLoading(false);
            console.log(errorObject);
        }
    }
    if (isLoading){
        return <div className={"d-flex align-items-center justify-content-center"} style={{height: '90vh'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }
    return (
        <div className={"container py-4"}>
            <h3 className={"mb-4 text-center"}>{categoryTitle}</h3>
            {
                (products && products.length > 0) && (
                    <div className={"d-flex flex-wrap justify-content-around"}>
                        {products.map((product) => (
                            <div key={product.id} className={'hit_card'}>
                                <Card product={product}/>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
}
export default Category