import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import star from '../assets/img/star.webp'
const Help = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('v-pills-payment');

    useEffect(() => {
        const fragment = location.hash.substring(1)
        if (fragment) {
            setActiveTab(fragment)
        }

    }, [location.hash])

    return (
        <div className="container py-5">
            <div className="d-flex align-items-start help_container">
                <div className="nav flex-column nav-pills me-3 nav_tabs_help" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{minWidth: '25%'}}>
                    <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-payment' ? 'active' : ''}`} id="v-pills-payment-tab" data-bs-toggle="pill" data-bs-target="#v-pills-payment" type="button" role="tab" aria-controls="v-pills-payment" aria-selected="true">
                        Условия оплаты
                    </button>
                    <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-delivery' ? 'active' : ''}`} id="v-pills-delivery-tab" data-bs-toggle="pill" data-bs-target="#v-pills-delivery" type="button" role="tab" aria-controls="v-pills-delivery" aria-selected="false">
                        Условия доставки
                    </button>
                    <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-guarantee' ? 'active' : ''}`} id="v-pills-guarantee-tab" data-bs-toggle="pill" data-bs-target="#v-pills-guarantee" type="button" role="tab" aria-controls="v-pills-guarantee" aria-selected="false">
                        Гарантия на товар
                    </button>
                    <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-privacy-policy' ? 'active' : ''}`} id="v-pills-privacy-policy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-privacy-policy" type="button" role="tab" aria-controls="v-pills-privacy-policy" aria-selected="false">
                        Политика конфиденциальности
                    </button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                    <div  className={`tab-pane fade ${activeTab === 'v-pills-payment' ? 'show active' : ''}`} id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab" tabIndex="0">
                        <div className="px-2">
                            <h3>Условия оплаты</h3>

                            <div className={'fs-5'}>Минимальная сумма заказа - <strong>50 000 рублей.</strong></div>

                            <div className={'mt-2'}>Заботясь об удобстве наших клиентов, мы предлагаем несколько способов оплаты:</div>
                            <ul>
                                <li className={'mt-2'}>  безналичным переводом на карту</li>
                                <li>оплата удобной вам криптовалютой (cryptocurrency)</li>
                                <li>Предоплата 40% от стоимости товара при оформлении заказа на сумму более 300 000 рублей</li>
                            </ul>
                            Способ оплаты вы можете согласовать с нашим менеджером в Whatsapp по телефону: <span className={'fw-bold'}>+7 996 221‑79‑03</span>
                        </div>
                    </div>
                    <div  className={`tab-pane fade ${activeTab === 'v-pills-delivery' ? 'show active' : ''}`} id="v-pills-delivery" role="tabpanel" aria-labelledby="v-pills-delivery-tab" tabIndex="0">
                        <div className="px-2">
                            <h3>Условия доставки</h3>
                            <div className={"px-2"}>
                                <div className={"py-3"}>
                                    <h5>Доставка по всей России</h5>
                                    <div className={"d-flex border border-danger rounded-3 p-2 align-items-center my-3"}>
                                        <img src={star} width={16} height={16} className={"me-2"} alt=""/>
                                        <p className={"m-0"}>Бесплатная доставка по России и приятный подарок от нашего магазина при заказе от 60 000 рублей.</p>
                                    </div>
                                    <table>
                                        <tbody>
                                        <tr className={"border border-dark-subtle"}>
                                            <td className={"border border-end-dark-subtle p-2"}>Служба доставки</td>
                                            <td className={"border border-end-dark-subtle p-2"}>Стоимость доставки, руб</td>
                                            <td className={"border border-end-dark-subtle p-2"}>Описание</td>
                                        </tr>
                                        <tr className={"border border-dark-subtle"}>
                                            <td className={"border border-end-dark-subtle p-2"}>Почта России</td>
                                            <td className={"border border-end-dark-subtle p-2"}>400</td>
                                            <td className={"border border-end-dark-subtle p-2"}>Доставка в отделение почты России вашего региона</td>
                                        </tr>

                                        <tr className={"border border-dark-subtle"}>
                                            <td className={"border border-end-dark-subtle p-2"}>СДЭК</td>
                                            <td className={"border border-end-dark-subtle p-2"}>Расчёт при оформлении</td>
                                            <td className={"border border-end-dark-subtle p-2"}>Экспресс-доставка по России и всему миру</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>

                                {/*<div className={"py-3"}>*/}
                                {/*    <h5>Доставка по Москве</h5>*/}
                                {/*    <div className={"d-flex border border-danger rounded-3 p-2 my-3"}>*/}
                                {/*        <img src={star} width={16} height={16} className={"me-2"} alt=""/>*/}
                                {/*        <p className={"m-0"}>Бесплатная доставка по Москве при заказе от 30000 рублей.</p>*/}
                                {/*    </div>*/}
                                {/*    /!*<p>Бесплатная доставка по Москве при заказе от 3000 рублей.</p>*!/*/}
                                {/*    <table className={"w-100"}>*/}
                                {/*        <tbody>*/}
                                {/*        <tr className={"border border-dark-subtle"}>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>Сумма заказа, руб</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>Москва (в пределах МКАД), руб</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>Подмосковью (за МКАД), руб</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr className={"border border-dark-subtle"}>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>до 2 999</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>300</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>от 500</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr className={"border border-dark-subtle"}>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>от 3 000</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>бесплатно</td>*/}
                                {/*            <td className={"border border-end-dark-subtle p-2"}>от 500</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}
                                {/*</div>*/}
                            </div>

                            <h6 className={"m-3 text-center"}>Если ни один из предложенных методов доставки не соответствует вашим требованиям, пожалуйста, свяжитесь с нашим менеджером в Whatsapp по телефону: +7 996 221‑79‑03. Мы готовы рассмотреть и обсудить вашу индивидуальную ситуацию! </h6>

                        </div>
                    </div>
                    <div  className={`tab-pane fade ${activeTab === 'v-pills-guarantee' ? 'show active' : ''}`} id="v-pills-guarantee" role="tabpanel" aria-labelledby="v-pills-guarantee-tab" tabIndex="0">
                        <div className="px-2">
                            <h3>Гарантия на товар</h3>
                            <p>Мы гарантируем качество наших товаров. Ниже представлены основные пункты гарантии:</p>

                            <ul>
                                <li>
                                    <strong>Срок гарантии:</strong>
                                    <p>Каждый товар поставляется с определенным сроком гарантии. Пожалуйста, проверьте информацию о гарантии на странице товара или в документации к товару.</p>
                                </li>
                                <li>
                                    <strong>Условия гарантии:</strong>
                                    <p>Гарантия распространяется на условия нормального использования товара. Повреждения, вызванные неправильным использованием, не покрываются гарантией.</p>
                                </li>
                                <li>
                                    <strong>Процедура возврата:</strong>
                                    <p>Если у вас возникли проблемы с товаром, свяжитесь с нашей службой поддержки. Мы предоставим вам подробную информацию о процедуре возврата и замены.</p>
                                </li>
                            </ul>

                            <p>В случае возникновения вопросов по гарантии, не стесняйтесь обращаться к нашей службе поддержки.</p>
                        </div>
                    </div>
                    <div  className={`tab-pane fade ${activeTab === 'v-pills-privacy-policy' ? 'show active' : ''}`} id="v-pills-privacy-policy" role="tabpanel" aria-labelledby="v-pills-privacy-policy-tab" tabIndex="0">
                        <div className="px-2">
                            <h4 className="text-center">Политика ООО «ВоенторгЗвезда» в отношении обработки персональных данных (Политика конфиденциальности)</h4>
                            <h6 className={"my-4"}>1. НАЗНАЧЕНИЕ И ОБЛАСТЬ ДЕЙСТВИЯ ДОКУМЕНТА</h6>
                            <p className={"py-2"}>1.1. «Политика ООО «Военторгзвезда» (далее по тексту также - Общество) в отношении обработки персональных данных» (далее – Политика) порядок сбора, хранения, передачи и иных видов обработки персональных данных, а также сведения о реализуемых требованиях к защите персональных данных.</p>
                            <p className={"py-2"}>1.2. Действие Политики распространяется на все персональные данные субъектов, обрабатываемые в Обществе с применением средств автоматизации и без применения таких средств.</p>
                            <p className={"py-2"}>1.3. К настоящей Политике имеет доступ любой субъект персональных данных.</p>
                            <p className={"py-2"}>1.4. Политика разработана в соответствии с действующим законодательством РФ.</p>
                            <h6 className={"my-4"}>2. СОСТАВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h6>
                            <p className={"py-2"}>2.1. Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (гражданину). Т.е. к такой информации, в частности, можно отнести: ФИО, год, месяц, дата и место рождения, адрес, сведения о семейном, социальном, имущественном положении, сведения об образовании, профессии, доходах, а также другую информацию.</p>
                            <p className={"py-2"}>2.2. Все обрабатываемые Обществом персональные данные являются конфиденциальной, строго охраняемой информацией в соответствии с законодательством.</p>
                            <p className={"py-2"}>2.3. Обработка персональных данных - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) можно отнести: сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                            <h6 className={"my-4"}>3. СУБЪЕКТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ ОБЩЕСТВА</h6>
                            <p className={"py-2"}>3.1. Общество обрабатывает персональные данные следующих лиц:</p>
                            <ul>
                                <li className={'privacy-policy-marker'}><p className={"py-2"}>субъектов, с которыми заключены договоры гражданско-правового характера;</p></li>
                                <li className={'privacy-policy-marker'}><p className={"py-2"}>клиентов Общества;</p></li>
                                <li className={'privacy-policy-marker'}><p className={"py-2"}>зарегистрированных пользователей сайта Общества;</p></li>
                                <li className={'privacy-policy-marker'}><p className={"py-2"}>посетителей сайта.</p></li>
                            </ul>
                            <h6 className={"my-4"}>4. ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h6>
                            <p className={"py-2"}>4.1. Целью обработки персональных данных является выполнения обязательств Оператора перед Пользователями в отношении использования Сайта и его сервисов.</p>
                            <p className={"py-2"}>4.2. Обработка персональных данных пользователей осуществляется с согласия субъекта персональных данных на обработку его персональных данных.</p>
                            <p className={"py-2"}>4.3. Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных) и которая может быть использована для точной идентификации определенного лица либо связи с ним.</p>
                            <p className={"py-2"}>4.4. Мы можем запросить у Вас персональные данные в любой момент, когда Вы связываетесь с Обществом. Общество может использовать такие данные в соответствии с настоящей Политикой обработки персональных данных. Она также может совмещать такую информацию с иной информацией для целей предоставления и улучшения своих продуктов, услуг, информационного наполнения (контента) и коммуникаций.</p>
                            <p className={"py-2"}>4.5. Под безопасностью персональных данных Общества понимает защищенность персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных и принимает необходимые правовые, организационные и технические меры для защиты персональных данных.</p>
                            <p className={"py-2"}>4.6. Обработка и обеспечение безопасности персональных данных в Обществе осуществляется в соответствии с требованиями Конституции Российской Федерации, Федерального закона № 152-ФЗ «О персональных данных» от 27 июля 2006 года, подзаконных актов, других определяющих случаи и особенности обработки персональных данных федеральных законов Российской Федерации, руководящих и методических документов ФСТЭК России и ФСБ России.</p>
                            <p className={"py-2"}>4.7. При обработке персональных данных Общество придерживается следующих принципов:</p>
                            <p className={"py-2"}>4.8. законности и справедливой основы;</p>
                            <p className={"py-2"}>4.9. ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;</p>
                            <p className={"py-2"}>4.10. недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;</p>
                            <p className={"py-2"}>4.11. недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</p>
                            <p className={"py-2"}>4.12. обработки персональных данных, которые отвечают целям их обработки;</p>
                            <h6 className={"my-4"}>5. ОБЩЕСТВО ОБРАБАТЫВАЕТ ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТОЛЬКО ПРИ НАЛИЧИИ ХОТЯ БЫ ОДНОГО ИЗ СЛЕДУЮЩИХ УСЛОВИЙ</h6>
                            <p className={"py-2"}>5.1. обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</p>
                            <p className={"py-2"}>5.2. обработка персональных данных необходима для достижения целей, предусмотренных законом, для осуществления и выполнения, возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;</p>
                            <p className={"py-2"}>5.3. обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</p>
                            <p className={"py-2"}>5.4. обработка персональных данных необходима для осуществления прав и законных интересов Общества или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</p>
                            <p className={"py-2"}>5.5. осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе;</p>
                            <p className={"py-2"}>5.6. осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</p>
                            <p className={"py-2"}>5.7. Общество вправе поручить обработку персональных данных граждан третьим лицам, на основании заключаемого с этими лицами договора. Лица, осуществляющие обработку персональных данных по поручению Общества обязуются соблюдать принципы и правила обработки и защиты персональных данных, предусмотренные Федеральным законом № 152-ФЗ «О персональных данных» от 27 июля 2006 года. Для каждого лица определены перечень действий (операций) с персональными данными, которые будут совершаться юридическим лицом, осуществляющим обработку персональных данных, цели обработки, установлена обязанность такого лица соблюдать конфиденциальность и обеспечивать безопасность персональных данных при их обработке.</p>
                            <p className={"py-2"}>5.8. В случаях, установленных законодательством Российской Федерации, Общество вправе осуществлять передачу персональных данных граждан.</p>
                            <h6 className={"my-4"}>6. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h6>
                            <p className={"py-2"}>6.1. Субъект персональных данных имеет право:</p>
                            <p className={"py-2"}>6.1.1. требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
                            <p className={"py-2"}>6.1.2. требовать перечень своих персональных данных, обрабатываемых Оператором и источник их получения;</p>
                            <p className={"py-2"}>6.1.4. обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействия при обработке его персональных данных.</p>
                            <p className={"py-2"}>6.1.5. сведения о порядке осуществления гражданином прав, предусмотренных Федеральным законом «О персональных данных» № 152-ФЗ от 27 июля 2017 года;</p>
                            <h6 className={"my-4"}>7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h6>
                            <p className={"py-2"}>7.1. Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных.</p>
                            <p className={"py-2"}>7.2. Настоящая Политика является внутренним документом Общества, и подлежит размещению на официальном сайте Общества.</p>
                            <p className={"py-2"}>7.3. Контроль исполнения требований настоящей Политики осуществляется ответственным за обеспечение безопасности персональных данных Общества.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
