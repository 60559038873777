import React, {useState} from 'react';
import night_glass1 from "../assets/img/night_glass1.jpg";
import star_review from "../assets/img/star_review.png";
import star_review_empty from "../assets/img/star_review_empty.png";
import {useNavigate} from "react-router-dom";
const Card = ({product}) => {
    const navigate = useNavigate()
    return(
        <div>
            <div onClick={() => navigate('/view/' + product.alias)}>
                <img src={ product?.photos?.length > 0 ? product?.photos[0].image_url : night_glass1} className={'hit_card_img cursor-pointer'} alt=""/>
            </div>
            <div>
                <div className={'hit_title cursor-pointer'} onClick={() => navigate('/view/' + product.alias)}>
                    {product?.name}
                </div>
                <div className={'hit_price'}>
                    {product?.sale_price?.toLocaleString() && <h2 className={"sale-price mb-0 me-3"}>{product?.sale_price?.toLocaleString()} руб.</h2>}
                    {product?.sale_price?.toLocaleString() ? <strike style={{color: 'red'}}><h5 className={"me-3 mb-0"} style={{color: '#00000080'}}>{product?.price?.toLocaleString()} руб. </h5></strike> : <h2 className={"me-3 mb-0"}>{product?.price?.toLocaleString()} руб.</h2>}
                </div>
                <div className={'hit_text'}>
                    {product?.pre_description}
                </div>

                <div className={'d-flex'}>
                    {[...Array(product?.star_count || 0)].map((_, index) => (
                        <img key={index} width={25} src={star_review} alt=""/>
                    ))}
                    {[...Array(5 - product?.star_count || 0)].map((_, index) => (
                        <img key={(product?.star_count || 0) + index} width={25} src={star_review_empty} alt=""/>
                    ))}
                </div>
                <div className={'d-flex justify-content-center mt-4'}>
                    <button className={'hit_btn cursor-pointer'} onClick={() => navigate('/view/' + product.alias)}>
                        Просмотреть
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Card