import React, {useState} from 'react';
import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";
const Register = () => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate()
    const handleSubmit = async () => {
        const url = process.env.REACT_APP_API_URL + 'register'

        let response = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                name: name,
                password: password,
            }),
        })

        if (response.ok) {
            let json = await response.json()

            if (json){
                Cookies.set('authenticated', true, { expires: 14 })
                localStorage.setItem('zvezda_user', JSON.stringify(json));
                navigate('/')
            }
        } else {
            const errorText = await response.text();
            const errorObject = JSON.parse(errorText);
            setError(errorObject.error);
        }
    }

    return (

    <div className="container ">
        <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-8 login_container" style={{marginBottom: 200, marginTop: 200}}>
                <div style={{ position: 'absolute', top: 120, left: '50%', transform: 'translateX(-50%)'}}>
                    {
                        error !== '' &&
                        <div className="alert alert-danger d-flex align-items-center" style={{padding: '15px 100px'}} role="alert">
                            <div style={{marginRight: 10}}>
                                <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.30928 9C8.59494 5 9.96832 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.45498 18.0635 2.24306 16.2635 4.05373 13" stroke="#ff0000" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M12 8V13" stroke="#ff0000" strokeWidth="1.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                            </div>
                            {error}
                        </div>
                    }
                </div>

                <div className="card" style={{borderRadius: 15}}>
                    <div className="card-body d-flex flex-column align-items-center">
                        <div className={"text-center pb-3 border-bottom border-danger w-100"}>
                            <h5>Регистрация</h5>
                        </div>
                        <div  className={'mt-3 login-form_container'}>
                            <div className={"mb-3"}>
                                <label className="form-label">Имя</label>
                                <input type="text" className="form-control custom_input" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className={"mb-3"}>
                                <label className="form-label">Почта</label>
                                <input type="email" className="form-control custom_input" id="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div  className={"mb-3"}>
                                <label className="form-label">Пароль</label>
                                <input type="password" className="form-control custom_input" id="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div className={"my-4 mt-5 d-block"}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <button className={"hit_btn mb-3"} onClick={handleSubmit} style={{ margin: '0 auto' }}>Зарегистрироваться</button>
                                    <button className={"none_btn"} onClick={() => navigate('/login')} style={{  margin: '0 auto' }}>Войти</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default Register