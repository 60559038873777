import React, {useEffect, useState} from 'react';
import Slider from "../components/Slider";
import money from '../assets/img/money.png'
import card from '../assets/img/card.png'
import delivery from '../assets/img/delivery.png'
import support from '../assets/img/support.png'
import {Collapse} from "react-bootstrap";
import Card from "../components/Card";


const Main = () => {
    const [openStates, setOpenStates] = useState(Array(8).fill(false));
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + 'home'
        fetch(url)
            .then(response => response.json())
            .then(json => {
                console.log(json)
                setData(json)
                setIsLoading(false);
            })
    }, []);

    const handleCategoryClick = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);
    };

    if (isLoading){
        return <div className={"d-flex align-items-center justify-content-center"} style={{height: '90vh'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    return (
        <div>
            <div className={'bg-main_section1'}>
                <div className={'container d-flex justify-content-center  '}>
                    <Slider/>
                </div>
            </div>
            <div className={'bg-main_section2 container  mt-3 mb-4'}>
                <div>
                    <div className={'d-flex justify-content-center advantages_container'}>
                        <div className={'d-flex card_container align-items-center advantages_container1'} style={{marginRight: 10}}>
                            <img src={money} height={50} alt=""/>
                            <div className={'d-flex flex-column card_right_side'}>
                                <div className={'card_title'}>
                                    Лучшая цена
                                </div>
                                <div className={'card_text'}>
                                    Самые выгодные предложения во всей России
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex card_container align-items-center advantages_container1'}  style={{marginLeft: 10}}>
                            <img src={delivery} height={50} alt=""/>
                            <div className={'d-flex flex-column card_right_side'}>
                                <div className={'card_title'}>
                                    Быстрая доставка
                                </div>
                                <div className={'card_text'}>
                                    Отправка товара в день заказа
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-center advantages_container'}>
                        <div className={'d-flex card_container align-items-center advantages_container1'} style={{marginRight: 10}}>
                            <img src={card} height={50} alt=""/>
                            <div className={'d-flex flex-column card_right_side'}>
                                <div className={'card_title'}>
                                    Безопасная покупка
                                </div>
                                <div className={'card_text'}>
                                    Мы гарантируем безопасную оплату
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex card_container align-items-center advantages_container1'}  style={{marginLeft: 10}}>
                            <img src={support} height={50} alt=""/>
                            <div className={'d-flex flex-column card_right_side'}>
                                <div className={'card_title'}>
                                    Всегда на связи
                                </div>
                                <div className={'card_text'}>
                                    Мы работаем 24 часа 7 дней в неделю
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={'container d-flex justify-content-center flex-column '}>
                    <h1 className={'text-center'}>Хит продаж</h1>
                    <div className={'hit_container'}>
                        {data && data.popular_products && data.popular_products.length > 0 && (
                            data.popular_products.map((product) => (
                                <div key={product.id} className={'hit_card'}>
                                    <Card product={product}/>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className={'container d-flex justify-content-center flex-column '}>
                    <h1 className={'text-center mt-4'}>Товары со скидкой</h1>
                    <div className={'sale_container'}>
                        {data && data.sale_products && data.sale_products.length > 0 && (
                            data.sale_products.map((product) => (
                                <div key={product.id} className={'sale_card'}>
                                    <Card product={product}/>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className={'container d-flex justify-content-center flex-column mb-4'}>
                    <h1 className={'text-center mt-4'}>Популярные товары своих категорий</h1>
                    <div className={"w-100"}>
                        {data && data.products_by_categories && data.products_by_categories.length > 0 && (
                            data.products_by_categories.map((categoryInfo, index) => (
                                <div style={{cursor:"pointer"}} key={index}>
                                    <div
                                        className={'drop_text'}
                                        onClick={() => handleCategoryClick(index)}
                                        aria-controls={`example-collapse-text-${index}`}
                                        aria-expanded={openStates[index]}>
                                        {categoryInfo.category.category_name}
                                    </div>
                                    <Collapse in={openStates[index]} className={"popular_category_container"}>
                                        <div id={`example-collapse-text-${index}`} key={`example-collapse-${index}`}>
                                            {categoryInfo?.products && categoryInfo.products.length > 0 && (
                                                categoryInfo.products.map((product) => (
                                                    <div key={product.id} className={'hit_card'}>
                                                        <Card product={product}/>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </Collapse>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};
export default Main;