import React, {useEffect, useState} from "react";
import Card from "../components/Card";
const Action = () => {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + 'auction'
        fetch(url)
            .then(response => response.json())
            .then(json => {
                setData(json)
                console.log(json)
                setIsLoading(false);
            })
    }, [])

    if (isLoading){
        return <div className={"d-flex align-items-center justify-content-center"} style={{height: '90vh'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }
    return(
        <div className={"container py-3"}>
            <h2 className={"text-center"}> Товары со скидкой </h2>
            <div className={"d-flex flex-wrap justify-content-between"}>
                {
                    data && data.length > 0 &&
                    data.map((product) => (
                        <div key={product.id} className={'sale_card'}>
                            <Card product={product}/>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}
export default Action