import './App.css';
import Main from './pages/main'
import Header from "./components/Header";
import Footer from "./components/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Help from "./pages/help";
import View from "./pages/view";
import Login from "./pages/login";
import Register from "./pages/register";
import Category from "./pages/category";
import {useEffect, useState} from "react";
import AboutCompany from "./pages/aboutCompany";
import Action from "./pages/action";

function App() {
    const [categories, setCategory] = useState([])
    const { pathname } = useLocation();

    useEffect( () => {
        getCategories()
    }, []);
    useEffect( () => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const getCategories = async () => {
        try{
        const url = process.env.REACT_APP_API_URL + 'categories'
        let response = await fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            let json = await response.json()
            if (json){
                setCategory(json);
            }
        } else {
            const errorText = await response.text();
            const errorObject = JSON.parse(errorText);
            console.log(errorObject);
        }
        }catch (e){
            console.log(e)
        }
    }

    return (
        <div className="App">
            <Header categories={categories}/>
            <div className={'app_container'}>
            <Routes>
                <Route path={'/'} element={<Main/>}/>
                <Route path={'/help'} element={<Help/>}/>
                <Route path={'/view/:alias'} element={<View/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/register'} element={<Register/>}/>
                <Route path={'/category/:alias'} element={<Category/>}/>
                <Route path={'/about'} element={<AboutCompany/>}/>
                <Route path={'/special-auction'} element={<Action/>}/>
            </Routes>
            </div>
            <Footer categories={categories}/>
        </div>
    );
}

export default App;
