import React, {useEffect, useState} from "react";
import star_review from "../assets/img/star_review.png";
import star_review_empty from "../assets/img/star_review_empty.png";
import delivery from "../assets/img/delivery.png";
import {useParams} from "react-router-dom";

const View = () => {
    const {alias} = useParams();

    // const [data, setData] = useState({
    //     id: 1,
    //     name: 'Портативная радиостанция VHF 4400 E Motorola',
    //     alias: 'wqeqwe',
    //     manufacturer: 'Motorola Solutions',
    //     product_code: '123340234',
    //     price: 90000.00,
    //     sale_price: 80000.00,
    //     sale_percent: 11,
    //     star_count: 4,
    //     photos: ['https://anvis.iaai.com/resizer?imageKeys=38337764~SID~B201~S1~I1~RW2576~H1932~TH0&width=845&height=633', 'https://9mm.ru/upload/iblock/191/2lvhg9frlayph2t3m628s1upp66956s9.webp', 'https://9mm.ru/upload/iblock/3c7/srw2nn684f7pdjwjbedws975ir7maeyr.webp'],
    //     description: 'Портативная радиостанция VHF 4400 E Motorola для работы в диапазоне VHF имеет множество необходимых для деятельности функций. К ним относятся усовершенствованная подача сигнала вызова, базовая и расширенная защита  переговоров, расширяемость за счет дополнительных плат и прерывание сигнала, позволяющее устанавливать приоритетность для важных сообщений. Четкий трехцветный светодиодный индикатор помогает в процессе вызова, сканирования и мониторинга. Кнопка экстренной связи позволяет уведомить руководителя или диспетчера о чрезвычайной ситуации. Функция "Интеллектуальный звук" автоматически регулирует уровень громкости радиостанции для компенсации фонового шума. Корпус радиостанции соответствует требованиям IP68: работает под водой на глубине 1 метр до 30 минут. Три программируемые кнопки на торце для быстрого доступа к часто используемым функциям. С помощью этих кнопок стало еще проще пользоваться такими функциями, как вызов нажатием одной кнопки и обмен текстовыми сообщениями.',
    //     characteristics: [
    //         {title: 'Производитель: Motorola Solutions', value: 'Motorola Solutions'},
    //         {title: 'Частота', value: 'VHF (136-174 МГц)'},
    //         {title: 'Тип', value: 'портативные'},
    //         {title: 'Режим работы', value: 'цифровой'},
    //         {title: 'Разъем антенны', value: 'RF (Motorola)'},
    //         {title: 'Количество каналов', value: '32'},
    //         {title: 'Мощность', value: '5 Вт'},
    //         {title: 'Диапазон рабочих температур', value: 'от -30 до +60 °С'},
    //         {title: 'Температура хранения', value: 'от -40 до +85 °C'},
    //         {title: 'Термальный удар', value: 'согласно MIL-STD'},
    //         {title: 'Пылевлагозащита в соответствии', value: 'IP68'},
    //         {title: 'Габариты', value: '130 x 55 x 34 мм'},
    //         {title: 'Вес', value: '290 г'},
    //     ],
    //     additional_data: [
    //         {
    //             title: 'Общие технические характеристики радиостанции',
    //             type: 'li',
    //             content: [
    //                 'Количество каналов: 32',
    //                 'Источник питания: 7,5 вольт',
    //                 'Средняя продолжительность работы аккумулятора: при комнатной температуре с циклом 5:5:90',
    //                 'Аналоговый режим: Аккумулятор (NiMH 1400 мА/ч): 7 часов',
    //                 'Аналоговый режим: Аккумулятор (LiIon 1600 мА/ч): 8 часов',
    //                 'Аналоговый режим: Аккумулятор (LiIon 2250 мА/ч): 11,5 часов',
    //                 'Аналоговый режим: Аккумулятор (LiIon 2350 мА/ч FM): 12,3 часов',
    //                 'Цифровой режим: Аккумулятор (NiMH 1400 мА/ч): 10,2 часов',
    //                 'Цифровой режим: Аккумулятор (LiIon 1600 мА/ч): 11,8 часов',
    //                 'Цифровой режим: Аккумулятор (LiIon 2250 мА/ч): 17 часов',
    //                 'Цифровой режим: Аккумулятор (LiIon 2350 мА/ч FM): 18,1 часов',
    //             ]
    //         },
    //         {
    //             title: 'Передатчик',
    //             type: 'li',
    //             content: [
    //                 'Мощность передатчика: 5 Вт',
    //                 'Разнос каналов: 12.5/20/25 кГц',
    //                 'Стабильность частоты (– 30°C, +60°C, +25°C): +/- 0,5 ppm',
    //                 'Ограничение модуляции: +/- 2,5 КГц (12,5KГц),+/- 4,0 КГц (20 КГц),+/- 5,0 КГц (25 КГц)',
    //                 'Избирательность по соседнему каналу: — 60 дБ (12,5 КГц),— 70 дБ (20, 25 КГц)',
    //                 'Чувствительность звукового канала: TIA603D',
    //                 'Искажение звука: 3%',
    //                 'ЧМ-фон и шум: -40 дБ (12.5 кГц),-45 дБ (20, 25 кГц)',
    //                 'Паразитные излучения (кондуктивные и по всему спектру частот):36 дБм < 1 ГГц / -30 дБм > 1 ГГц',
    //                 'Сочетание 12,5 кГц Голос и данные: 7K60F1W',
    //                 'Тип цифрового устройства кодирования речи: AMBE+2™',
    //                 'Цифровой протокол: ETSI TS102 361-1, -2, -3',
    //             ]
    //         },
    //         {
    //             title: 'Приемник',
    //             type: 'li',
    //             content: [
    //                 'Разнос каналов: 12,5/20/25 кГц',
    //                 'Стабильность частоты (-30°C, +60°C, +25°C справ.): ± 0.5 ppm',
    //                 'Чувствительность по аналоговому сигналу (12 дБ SINAD): 0,3 мкВ (0,22 мкВ типичная)',
    //                 'Чувствительность по цифровому сигналу: 0,25 мкВ (0,19 мкВ типичная)',
    //                 'Подавление интермодуляционных искажений (TIA603D): 70 дБ',
    //                 'Adjacent Channel Selectivity (TIA603A)-1T: 60 дБ при 12,5 кГц / 70 дБ при 20/25 кГц',
    //                 'Избирательность по соседнему каналу (TIA603D)-2T: 45 дБ при 12,5 кГц / 70 дБ при 20/25 кГц',
    //                 'Подавление ложного сигнала (TIA603D): 70 дБ',
    //                 'Звуковая мощность при номинальном сигнале: 0,5 Вт',
    //                 'Искажение звука при номинальной мощности: 5% (3% типичное)',
    //                 'Шумы и помехи: -40 дБ при 12,5 кГц / -45 дБ при 20/25 кГц',
    //                 'Чувствительность звукового канала: TIA603D',
    //                 'Кондуктивные паразитные излучения (TIA603D): -57 дБм',
    //             ]
    //         },
    //         {
    //             title: 'Особенности Motorola VHF 4400E',
    //             type: 'li',
    //             content: [
    //                 'Цифровой протокол DMR',
    //                 '2 канала связи в полосе 12,5 кГц',
    //                 'Одновременное сканирование аналоговых и цифровых каналов, обеспечивающее плавный переход с аналоговых стандартов на цифровой',
    //                 'Кнопка экстренной связи',
    //                 'Возможность программирования по радиосети',
    //                 'Три программируемые кнопки для быстрого доступа',
    //                 'Трехцветный светодиодный индикатор',
    //                 'Усовершенствованный разъем для аксессуаров, позволяющий сохранять работоспособность даже под водой на глубине до 1 м в течении 30 минут',
    //                 'Соединение с ПК через интерфейс USB',
    //                 'Возможность подключения усовершенствованных аудиопринадлежностей'
    //             ]
    //         },
    //         {
    //             title: 'Комплектация',
    //             type: 'li',
    //             content: [
    //                 'Радиостанция',
    //                 'Антенна',
    //                 'Аккумулятор',
    //                 'Настольное зарядное устройство',
    //                 'Клипса',
    //                 'Руководство пользователя'
    //             ]
    //         },
    //     ]
    // })

    const [data, setData] = useState({})
    const [additionalData, setAdditionalData] = useState([])
    const [characteristics, setCharacteristics] = useState([])
    const [selectedPhoto, setSelectedPhoto] = useState(null)
    const [selectedNumber, setSelectedNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + 'show-product/' + alias
        fetch(url)
            .then(response => response.json())
            .then(json => {
                setData(json)
                console.log(json)
                if (json.additional_data) {
                    setAdditionalData(JSON.parse(json.additional_data))
                }
                if (json.characteristics) {
                    setCharacteristics(JSON.parse(json.characteristics))
                }
                setIsLoading(false);
            })
    }, []);



    const addToBasket = async () => {
        const userString = localStorage.getItem('zvezda_user');
        if (!userString) {
            return null;
        }

        const parsedUser = JSON.parse(userString);
        if (!parsedUser || !parsedUser.id) {
            return null;
        }


        setIsLoading(true)
        const url = process.env.REACT_APP_API_URL + 'add-to-basket/' + parsedUser.id

        let response = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                product_id: data.id,
                count: selectedNumber
            }),
        })

        if (response.ok) {
            let json = await response.json()
            console.log(json);
            if (json) {
                setIsLoading(false)
            }
            localStorage.setItem('refreshHeader',new Date().toString())
        } else {
            const errorText = await response.text();
            const errorObject = JSON.parse(errorText);
            setIsLoading(false)
            console.log(errorObject);
        }
    }

    if (isLoading) {
        return <div className={"d-flex align-items-center justify-content-center"} style={{height: '90vh'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    return (
        <div className={'container'} style={{marginTop: 20}}>
            <div className={"row custom_view_column"} style={{maxHeight: 600, minHeight: 600}}>
                <div className={"col-6 carousel-fix"}>
                    <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                            {
                                data?.photos?.map((photo, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}
                                         onClick={() => setSelectedPhoto(photo.image_url)}>
                                        <img src={photo.image_url} className="d-block w-100" alt={`Slide ${index + 1}`}
                                             data-bs-toggle="modal" data-bs-target="#exampleModal"
                                             style={{maxHeight: 600}}/>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                             aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered zoom_photo"
                                 style={{maxWidth: '60vw', maxHeight: 400}}>
                                <div className="modal-body">
                                    <img src={selectedPhoto || 'null'} alt="" className="d-block w-100"
                                         data-dismiss="modal" style={{maxHeight: 900}}/>
                                </div>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                                data-bs-slide="prev">
                            <div className="custom_previous">
                                <svg fill="#ff0000" height="40px" width="40px" version="1.1" id="XMLID_54_"
                                     xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     viewBox="0 0 24 24" xmlSpace="preserve" stroke="#ff0000">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g id="previous">
                                            <g>
                                                <polygon
                                                    points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 "></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample"
                                data-bs-slide="next">
                            <div className="custom_previous custom_next">
                                <svg fill="#ff0000" height="40px" width="40px" version="1.1" id="XMLID_54_"
                                     xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     viewBox="0 0 24 24" xmlSpace="preserve" stroke="#ff0000"
                                    >
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g id="previous">
                                            <g>
                                                <polygon
                                                    points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 "></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
                <div className={"col-5 ms-4 custom_info-block"}>
                    <h3 className={"title_view_product"}>{data.name}</h3>

                    <div className={'d-flex align-items-center my-3 custom-delivery_info'}>
                        <div className={'d-flex me-3'}>
                            {[...Array(data?.star_count || 0)].map((_, index) => (
                                <img key={index} width={25} src={star_review} alt=""/>
                            ))}
                            {[...Array(5 - data?.star_count || 0)].map((_, index) => (
                                <img key={(data?.star_count || 0) + index} width={25} src={star_review_empty} alt=""/>
                            ))}
                        </div>
                        {
                            data?.star_count === 5 && (
                                <div>
                                    Покупатели рекомендуют
                                </div>
                            )
                        }

                    </div>

                    <div className={"in_stock_product_code"}>
                        <div className={"available"}>
                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" stroke="#098a00">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#0f8500" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"></path>
                                </g>
                            </svg>
                            Есть в наличии
                        </div>

                        <div className={"pt-4"}>
                            <div className={"d-flex align-items-center"}>
                                <p style={{fontWeight: '600', marginBottom: 5, marginRight: 5}}>Код товара: </p>
                                <p style={{marginBottom: 5}}>{data?.product_code}</p>
                            </div>
                            <div className={"d-flex align-items-center"}>
                                <p style={{fontWeight: '600', marginBottom: 5, marginRight: 5}}>Производитель: </p>
                                <p style={{marginBottom: 5}}>{data?.manufacturer}</p>
                            </div>
                        </div>
                    </div>


                    <div className={"price_and_buy_button"}>

                        <div className={"my-3"}>
                            {data?.sale_price && <h2 className={"sale-price mb-0 me-3"}>{data?.sale_price} руб.</h2>}
                            {data?.sale_price ? <strike style={{color: 'red'}}><h5 className={"me-3 mb-0"}
                                                                                   style={{color: '#00000080'}}>{data?.price} руб. </h5>
                            </strike> : <h2 className={"me-3 mb-0"}>{data?.price} руб.</h2>}
                        </div>

                        <div>
                            <div className={"my-3 d-flex rounded-3"}
                                 style={{backgroundColor: '#0000001f', width: 'fit-content'}}>
                                <button className={"plus_minus_btn"}
                                        onClick={() => selectedNumber >= 1 && setSelectedNumber(selectedNumber - 1)}>
                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M6 12L18 12" stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </button>
                                <input className={"custom_number_input"} type="number" min={0} value={selectedNumber}
                                       onChange={(e) => setSelectedNumber(e.target.value)}/>
                                <button className={"plus_minus_btn"}
                                        onClick={() => setSelectedNumber(selectedNumber + 1)}>
                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M4 12H20M12 4V20" stroke="#000000" strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <button className={"hit_btn px-4"} onClick={addToBasket}>
                                    <p className={"m-0"}>Купить</p>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className={"d-flex align-items-center my-3"}>
                        <img src={delivery} height={50} alt="" className={"mx-3"}/>
                        <div>
                            <p style={{fontWeight: 600}}>Доставка по всей России</p>
                            <p style={{fontSize: 13}}>Минимальная сумма заказа для бесплатной доставки - 60 000 рублей.
                                Способ оплаты согласовывается с менеджером. Оплата производится только после
                                подтверждения наличия товара менеджерами.</p>
                        </div>
                    </div>

                </div>
            </div>
            <nav className={"mt-4"}>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link custom-nav-link active" id="nav-description-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-description" type="button" role="tab" aria-controls="nav-description"
                            aria-selected="true">Описание
                    </button>
                    <button className="nav-link custom-nav-link" id="nav-characteristics-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-characteristics" type="button" role="tab"
                            aria-controls="nav-characteristics"
                            aria-selected="false">Характеристики
                    </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active py-3" id="nav-description" role="tabpanel"
                     aria-labelledby="nav-description-tab" tabIndex="0">
                    <div className={"my-5"}>
                        <h5>Описание</h5>
                        <p>{data?.description}</p>
                    </div>

                    <div className={"my-5"}>
                        <h5>Дополнительная информация</h5>
                        {
                            // eslint-disable-next-line array-callback-return
                            additionalData?.map((content, index) => {
                                if (content && content.type === 'li') {
                                    console.log(content)
                                    return (
                                        <div key={index}>
                                            <h6 className={"mt-4"}>{content.title}</h6>
                                            <ul>
                                                {
                                                    content?.content.map((text, index) => (
                                                        <li className={'privacy-policy-marker'} key={index}>{text}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (content && content.type === 'text') {
                                    return (
                                        <div key={index}>
                                            <h6 className={"mt-4"}>{content.title}</h6>
                                            <p>{content?.content}</p>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                </div>
                <div className="tab-pane fade py-3" id="nav-characteristics" role="tabpanel"
                     aria-labelledby="nav-characteristics-tab" tabIndex="0">
                    <h5>Характеристики</h5>
                    {
                        characteristics?.map((characteristic, index) =>
                            <div className={"row my-2"} key={index}>
                                <div className={"col-6 text-start"}>
                                    {characteristic.title}
                                </div>
                                <div className={"col-6 text-end"}>
                                    {characteristic.value}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>


        </div>
    )
}

export default View;


