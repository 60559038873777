import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
const AboutCompany = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('v-pills-about');

    useEffect(() => {
        const fragment = location.hash.substring(1)
        if (fragment) {
            setActiveTab(fragment)
        }
    }, [location.hash])

    return(
        <div className="container py-5">
            <div className="d-flex align-items-start help_container">
            <div className="nav flex-column nav-pills me-3 nav_tabs_help" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{minWidth: '25%'}}>
                <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-about' ? 'active' : ''}`} id="v-pills-about-tab" data-bs-toggle="pill" data-bs-target="#v-pills-about" type="button" role="tab" aria-controls="v-pills-about" aria-selected="true">
                    О компании
                </button>
                <button className={`nav-link nav-tabs-link ${activeTab === 'v-pills-oferta' ? 'active' : ''}`} id="v-pills-oferta-tab" data-bs-toggle="pill" data-bs-target="#v-pills-oferta" type="button" role="tab" aria-controls="v-pills-oferta" aria-selected="false">
                    Публичная оферта
                </button>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
                <div  className={`tab-pane fade ${activeTab === 'v-pills-about' ? 'show active' : ''}`} id="v-pills-about" role="tabpanel" aria-labelledby="v-pills-about-tab" tabIndex="0">
                    <h1 className="text-center mb-4">Добро пожаловать в интернет-магазин «ВоенторгЗвезда»</h1>

                    <p className="lead">Мы — интернет-магазин, специализирующийся на военной оптике и других комплектующих для настоящих профессионалов!</p>

                    <div className="alert alert-info">
                        <p><strong>Почему мы?</strong></p>
                        <ul>
                            <li><strong>Профессиональная специализация:</strong> Мы специализируемся на военной оптике и
                                комплектующих, предназначенных для профессионалов.
                            </li>
                            <li><strong>Лучшие цены:</strong> Наши низкие цены обеспечиваются прямым сотрудничеством с
                                китайскими поставщиками.
                            </li>
                            <li><strong>Скидки от объема:</strong> При заказе от 2 единиц предоставляется скидка 5%, а
                                при заказе от 3 единиц — скидка 10%.
                            </li>
                        </ul>
                    </div>

                    <div className="alert alert-warning">
                        <p><strong>Важно:</strong> Оплата производится ТОЛЬКО после подтверждения наличия товара менеджерами!!!</p>
                        <p>Все вопросы вы можете согласовать с нашим менеджером в Whatsapp по телефону: +7 996 221‑79‑03</p>
                    </div>
                </div>
                <div  className={`tab-pane fade ${activeTab === 'v-pills-oferta' ? 'show active' : ''}`} id="v-pills-oferta" role="tabpanel" aria-labelledby="v-pills-oferta-tab" tabIndex="0">
                    <h3>Публичная оферта</h3>
                    <p>Термины:</p>
                    <ul>
                        <li className={'privacy-policy-marker'}><p><strong>Покупатель</strong> — полностью дееспособное физическое лицо, размещающее Заказы на сайте voentorgzvezda.ru, либо указанное в качестве получателя Товара, либо использующее Товары, приобретенные на сайте voentorgzvezda.ru, исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Продавец</strong> — ООО ВоенторгЗвезда</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Сайт</strong> — voentorgzvezda.ru</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Товар</strong> — материальный объект, представленный к продаже на Сайте.</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Заказ</strong> — должным образом оформленный запрос Покупателя на доставку по указанному адресу перечня Товаров, выбранных на Сайте.</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Служба доставки</strong> — третье лицо, оказывающее по договору с Продавцом услуги по доставке Заказов Покупателям.</p></li>
                        <li className={'privacy-policy-marker'}><p><strong>Внешний сайт</strong> — сайт в глобальной сети Интернет, ссылка на который размещена на сайте voentorgzvezda.ru.</p></li>
                    </ul>

                    <i style={{fontSize: 14}}>Все остальные термины и определения, должны определяться в соответствии с толкованием, сложившимся в сети Интернет, практикой делового оборота и действующим законодательством Российской Федерации.</i>

                    <h5 className={"pt-4"}>1. Общие положения</h5>
                    <p>1.1. Заказывая товары через Интернет-магазин, Покупатель соглашается с Условиями продажи товаров (далее - Условия), изложенными ниже.</p>
                    <p>1.2. Настоящие Условия, также информация о Товаре, представленная на Сайте, являются публичной офертой в соответствии со ст.435 и ч.2 ст.437 ГК РФ.</p>
                    <p>1.3. К отношениям между Покупателем и Продавцом применяются положения ГК РФ о розничной купле-продаже (§ 2 глава 30), а также Закон РФ "О защите прав потребителей" от 07.02.1992 № 2300-1 и иные правовые акты, принятые в соответствии с ними.</p>
                    <p>1.4. Продавец оставляет за собой право вносить изменения в настоящие Условия, в связи с чем, Покупатель обязуется регулярно отслеживать изменения на сайте.</p>
                    <p>1.5. Покупатель соглашается с Условиями нажатием кнопки "Купить" на последнем этапе оформления Заказа на Сайте.</p>

                    <h5 className={"pt-4"}>2. Регистрация на Сайте</h5>
                    <p>2.1. Покупатель может зарегистрироваться на сайте либо сделать заказ анонимно. Однако, без указания контактной информации (телефон и адрес доставки), оформление заказа невозможно.</p>
                    <p>2.2. Продавец не несет ответственности за точность и правильность информации, предоставляемой Покупателем при регистрации.</p>
                    <p>2.3. Покупатель обязуется не сообщать третьим лицам логин и пароль, указанные при регистрации. В случае возникновения у Покупателя подозрений относительно безопасности его логина и пароля или возможности их несанкционированного использования третьими лицами, Покупатель обязуется незамедлительно уведомить об этом Продавца, направив электронное письмо в Службу по работе с клиентами –  voentorgzvezda.ru</p>

                    <h5 className={"pt-4"}>2. Регистрация на Сайте</h5>
                    <p>3.1. Заказ Покупателя может быть оформлен следующими способами: принят по телефону или оформлен Покупателем самостоятельно на Сайте.</p>
                    <p>3.2. При оформлении Заказа Покупатель должен указать следующую информацию:</p>
                    <p>- Ф.И.О. Покупателя либо Получателя Заказа;</p>
                    <p>- Адрес доставки Заказа;</p>
                    <p>- Контактный телефон;</p>
                    <p>- Электронную почту.</p>
                    <p>3.3. После оформления заказа Покупателю предоставляется информация об ожидаемой дате передачи Заказа в Службу доставки. Эта дата означает срок, в который Продавец обязуется передать Заказ Покупателя в выбранную им при оформлении Заказа Службу доставки. Указанная дата зависит от наличия заказанных Товаров на складе Продавца и времени, необходимого на обработку Заказа.</p>
                    <p>Конечные сроки получения Заказа Покупателем зависят от адреса и региона доставки, работы конкретной Службы доставки, и напрямую не зависят от Продавца.</p>
                    <p>3.4. Если Покупателем оформлен Заказ на товар, который отсутствует у Продавца на складе, то Продавец информирует об этом Покупателя посредством направления электронного сообщения. Сообщение направляется по электронному адресу, указанному при регистрации. Покупатель вправе согласиться принять аналогичный Товар предлагаемый Продавцом взамен отсутствующего, либо аннулировать данную позицию Товара из Заказа. В случае неполучения ответа Покупателя в течение 5 дней, Продавец оставляет за собой право аннулировать данный Товар из Заказа или Заказ полностью.</p>
                    <p>3.4.1. Продавец вправе аннулировать Заказы Покупателя, содержащие Товары, от которых ранее Покупатель отказался 3 и более раз, указав причины, не связанные с наличием недостатков в этих Товарах.</p>
                    <p>3.5. Все информационные материалы, представленные на Сайте, носят справочный характер и не могут в полной мере передавать достоверную информацию о свойствах и характеристиках Товара, включая цвета, размеры и формы. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа, Покупатель должен обратиться к Продавцу.</p>
                    <p>3.6. В случае аннуляции полностью либо частично предоплаченного Заказа стоимость аннулированного Товара возвращается Продавцом Покупателю тем способом, которым Товар изначально был предоплачен или любым другим, согласованным между Покупателем и продавцом способом.</p>
                    <p>3.7. Указанная на Сайте цена на товар, может меняться по усмотрению Продавца. Ценой расчета между Покупателем и Продавцом является цена, действующая на момент оформления заказа.</p>
                    <p>3.8. Особенности продажи уцененного товара: продавец вправе предлагать к продаже товар, имеющий недостатки, указываемые в описании товара на сайте, а также в сопроводительной документации к товару. Покупатель вправе приобретать уцененный товар или нет.</p>

                    <h5 className={"pt-4"}>4. Доставка</h5>
                    <p>4.1. Способы доставки товаров указаны на Сайте в разделе «Бесплатная доставка» http://voentorgzvezda.ru//help#v-pills-delivery</p>
                    <p>4.2. Продавец приложит все усилия для соблюдения сроков доставки, указанных на Сайте, тем не менее, задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца.</p>
                    <p>4.3. Стоимость доставки каждого Заказа рассчитывается индивидуально, исходя из его веса, региона и способа доставки, а иногда и формы оплаты, и согласовывается с Покупателем после оформления Заказа на Сайте или по телефону.</p>
                    <p>4.4. При доставке, Заказ вручается Покупателю либо лицу, указанному в качестве Получателя Заказа. При невозможности получения Заказа, оформленного за наличный расчет, указанными выше лицами, Заказ вручается лицу, готовому предоставить сведения о заказе (номер отправления и/или ФИО Получателя), а также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа.</p>
                    <p>4.5. Во избежание случаев мошенничества, а также для выполнения взятых на себя обязательств в пункте 4.6., при вручении предоплаченного Заказа лицо, осуществляющее доставку Заказа, вправе затребовать документ, удостоверяющий личность Получателя, а также указать тип и номер предоставленного Получателем документа на квитанции к Заказу. Продавец гарантирует конфиденциальность и защиту персональной информации Получателя (п.9.3.1.).</p>
                    <p>4.6. При передаче Заказа Покупатель должен проверить внешний вид и упаковку Заказа, количество Товара в Заказе, комплектность, ассортимент.</p>

                    <h5 className={"pt-4"}>5. Оплата Товара</h5>
                    <p>5.1. Цена Товара указывается на Сайте. В случае неверного указания цены заказанного Покупателем Товара, Продавец при первой возможности информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо аннулирования Заказа. При невозможности связаться с Покупателем данный Заказ считается аннулированным. Если Заказ был оплачен, Продавец возвращает Покупателю оплаченную за Заказ сумму тем же способом, которым она была переведена ему или любым другим, согласованным между Покупателем и продавцом способом.</p>
                    <p>5.2. Цена Товара может быть изменена Продавцом в одностороннем порядке. Цена Товара указывается на последнем этапе оформления Заказа и действительна на момент нажатия кнопки "Оформить заказ".</p>
                    <p>5.3. Способы оплаты Товара указаны на Сайте в разделе "Все виды оплаты" https://voentorgzvezda.ru/help#v-pills-payment.</p>
                    <p>5.5. Особенности оплаты Товара с помощью банковских карт</p>
                    <p>5.5.1. В соответствии с положением ЦБ РФ "Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт" от 24.12.2004 №266-П операции по банковским картам совершаются держателем карты либо уполномоченным им лицом.</p>
                    <p>5.5.3. Порядок оплаты с помощью банковских карт указан на Сайте в разделе "Все виды оплаты" https://voentorgzvezda.ru/help#v-pills-payment.</p>
                    <p>5.6. Продавец вправе устанавливать скидки в целях продвижения того либо иного способа оплаты или доставки Товара.</p>

                    <h5 className={"pt-4"}>6. Возврат Товара</h5>
                    <p>6.1. Возврат товара.</p>
                    <p>6.1.1. Покупатель вправе отказаться от заказанного Товара в любое время до его получения, а также после получения Товара - в течение 14 дней, включая день покупки. Возврат Товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного Товара.</p>
                    <p>6.1.2. Покупатель не вправе отказаться от Товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный Товар может быть использован исключительно приобретающим его Покупателем.</p>
                    <p>6.1.3. При отказе Покупателя от Товара согласно п.6.1.1. Условий Продавец возвращает ему стоимость возвращенного Товара, за исключением расходов продавца на доставку от Покупателя возвращенного Товара, не позднее чем через 10 дней с даты получения Продавцом от Покупателя возвращаемого товара.</p>
                    <p>6.1.4. В течение 14 дней с момента получения Заказа, не считая дня покупки, Покупатель вправе обменять товар надлежащего качества на аналогичный товар, если указанный товар не подошел Покупателю по форме, габаритам, фасону, расцветке, размеру или комплектации.</p>
                    <p>Покупатель не вправе обменять Товары надлежащего качества, указанные в Перечне непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену, утвержденном Постановлением Правительства РФ от 19.01.1998 № 55.</p>
                    <p>6.1.5. Если на момент обращения Покупателя аналогичный товар отсутствует в продаже у Продавца, Покупатель вправе отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за указанный товар денежной суммы. Продавец обязан вернуть уплаченную за возвращенный товар денежную сумму в течение 3 дней со дня возврата товара.</p>
                    <p>6.3. Возврат денежных средств</p>
                    <p>6.3.1. Денежные средства подлежат возврату тем способом, который был использован Покупателем при оплате Товара или любым другим, согласованным между Покупателем и продавцом способом.</p>
                    <p>6.3.2. Возврат денежных средств осуществляется после возврата товара Покупателем.</p>
                    <p>6.4. Порядок действий при нарушении продавцом условия об ассортименте (пересорте).</p>
                    <p>6.4.1. В случае передачи Товара в нарушении условия об ассортименте не применяются правила ст. 468 ГК РФ.</p>
                    <p>6.4.2. В случае обнаружения в Заказе Товара, не соответствующего заказанному ассортименту (пересорт), Покупатель вправе при получении заказа отказаться от данного Товара и потребовать замены на Товар в ассортименте, предусмотренном Заказом.</p>
                    <p>6.4.3. В случае невозможности осуществить замену Товара, Продавец уведомляет об этом Покупателя посредством направления сообщения на электронный адрес, указанный Покупателем при регистрации, а денежные средства, фактически оплаченные за непереданный товар, возвращаются.</p>

                    <h5 className={"pt-4"}>7. Интеллектуальная собственность</h5>
                    <p>7.1. Вся текстовая информация и графические изображения, находящиеся на Сайте являются собственностью Продавца и/или его контрагентов. Копирование материалов с сайта запрещено.</p>

                    <h5 className={"pt-4"}>8. Гарантии и ответственность</h5>
                    <p>8.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, заказанных на Сайте.</p>
                    <p>8.2. Продавец не несет ответственности за содержание и функционирование Внешних сайтов.</p>
                    <p>8.3. Продавец вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</p>
                    <p>8.4. Покупатель обязуется не использовать заказанный Товар в предпринимательских целях.</p>

                    <h5 className={"pt-4"}>9. Конфиденциальность и защита персональной информации</h5>
                    <p>9.1. Предоставление информации Покупателем:</p>
                    <p>9.1.1. При регистрации на Сайте Покупатель предоставляет следующую информацию: Фамилия, Имя, адрес электронной почты, пароль для доступа к Сайту.</p>
                    <p>9.2. Предоставляя свои персональные данные при оформлении заказа на сайте, Покупатель соглашается на их обработку Продавцом, в том числе и в целях продвижения Продавцом товаров и услуг, а именно - получать на указанный при регистрации адрес электронной почты или номер телефона информацию о рекламных акциях Продавца, распродажах и иные, связанные с непосредственной деятельностью Продавца, маркетинговые уведомления.</p>
                    <p>9.2.1. Если Покупатель не желает, чтобы его персональные данные обрабатывались, то он должен обратиться в Службу по работе с клиентами Продавца voentorgzvezda.ru.</p>
                    <p>9.3. Использование информации предоставленной Покупателем и получаемой Продавцом.</p>
                    <p>9.3.1 Продавец использует информацию:</p>
                    <p>- для регистрации Покупателя на Сайте;</p>
                    <p>- для выполнения своих обязательств перед Покупателем;</p>
                    <p>- для оценки и анализа работы Сайта;</p>
                    <p>- для определения победителя в акциях, проводимых Продавцом.</p>
                    <p>9.3.2. Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. Если Покупатель не желает получать рассылки от Продавца, он должен обратиться в Службу по работе с клиентами Продавца voentorgzvezda.ru.</p>
                    <p>9.4. Разглашение информации, полученной Продавцом:</p>
                    <p>9.4.1. Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением предоставление Продавцом информации агентам и третьим лицам, действующим на основании договора с Продавцом, для исполнения обязательств перед Покупателем.</p>
                    <p>9.4.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.</p>
                    <p>9.5. Продавец не несет ответственности за сведения, предоставленные Покупателем на Сайте в общедоступной форме.</p>

                    <h5 className={"pt-4"}>10. Прочие условия</h5>
                    <p>10.1. К отношениям между Покупателем и Продавцом применяется право Российской Федерации.</p>
                    <p>10.2. В случае возникновения вопросов и претензий со стороны Покупателя он должен обратиться в Службу по работе с клиентами Продавца по телефону или через e-mail: voentorgzvezda.ru. Все возникающее споры стороны будут стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РФ.</p>

                    <div className={"my-5"}>
                        <p>ООО «ВоенторгЗвезда»</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default AboutCompany